// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DocumentMangementAPIs from '../../../../apis/documentManagement'

export const getData = createAsyncThunk('appDepartment/getData', async params => {
	console.log("params : ", params)
	const response = await DocumentMangementAPIs.webAppList({
		...params,
		search: params.q,
		parameter: {
			siteID: params.siteID
		}
	})
	const totalResult = response.data.records?.[0]?.totalResult || 0
    // const totalPages = Math.ceil(totalResult / 10)
	return {
		params,
		data: response.data.list,
		allData: response.data.list,
		totalPages: totalResult
		// totalPages: response.data.records
	}
})

export const addDocument = createAsyncThunk('appDepartment/addInvoice', async (data, { dispatch, getState }) => {
	await DocumentMangementAPIs.createNewOne({ body: data })
	await dispatch(getData({
		...getState().document.params
	}))
})

export const setModalDataViewTask = createAsyncThunk('appDepartment/setModalDataViewTask', async params => {
	return {
		modalDataViewTask: {
			isModalOpenView: params.isModalOpenView,
			id: params.id
		}
	}
})

export const setModalDataEditTask = createAsyncThunk('appDepartment/setModalDataEditTask', async params => {
	return {
		modalDataEditTask: {
			isModalOpenEditView: params.isModalOpenEditView,
			id: params.id
		}
	}
})

export const getOneData = createAsyncThunk('appDepartment/getOneData', async params => {
	try {
		const response = await DocumentMangementAPIs.findOne(params)

		return {
			documentDetails : response.data
		}
	} catch (error) {
		showErrorToast(error)
	}
})

export const editDocument = createAsyncThunk('appDepartment/edit', async (data, { dispatch, getState }) => {
	console.log("payload", data)
	await DocumentMangementAPIs.updateOne({ body: data })
	await dispatch(getData({
		...getState().document.params
	}))
	// await dispatch(removeData())
})

export const appDepartmentSlice = createSlice({
	name: 'appDepartment',
	initialState: {
		data: [],
		total: 1,
		params: {},
		allData: [],
		simpleList: [],
		modalData: {
			isModelOpen: false,
			id: ''
		},
		documentDetails:{},
		modalDataViewTask: {
			isModalOpenView: false,
			id: ''
		},
		modalDataEditTask: {
			isModalOpenEditView: false,
			id: ''
		},
		userList: []
	},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getData.fulfilled, (state, action) => {
			if (action.payload.params.isParamChanged) {
				state.data = action.payload.data
			} else {
				state.data = [...state.data, ...action.payload.data]
			}

			state.allData = action.payload.allData
			state.total = action.payload.totalPages
			state.params = action.payload.params
			state.checklists = action.payload.checklists
		}).addCase(setModalDataViewTask.fulfilled, (state, action) => {
			state.modalDataViewTask = action.payload.modalDataViewTask
		}).addCase(setModalDataEditTask.fulfilled, (state, action) => {
			state.modalDataEditTask = action.payload.modalDataEditTask
		}).addCase(getOneData.fulfilled, (state, action) => {
			state.documentDetails = action.payload.documentDetails
		})
	}
})

export default appDepartmentSlice.reducer
